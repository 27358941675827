import Cookies from "js-cookie";
import Home_fila from "./pages/Home_fila";
import { AppRoutes } from "./routes/AppRoutes";

function App() {
  /*
  console.log("cokies");
  const coki = Cookies.get('QueueFair-Pass-desarrollofila');

  return (
    coki ? <AppRoutes/> : <Home_fila />
  );*/
  return <AppRoutes></AppRoutes>
}

export default App;
