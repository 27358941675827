import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import ErrorPago from '../components/voucher/ErrorPago';
import ErrorSinDato from '../components/voucher/ErrorSinDato';
import ResultadoPago from '../components/voucher/ResultadoPago';
import {Global} from '../utils/Global';
const api_url=Global.apiurl;

async function cargarSession(session_id=null){
    
      var requestOptions = {
        method: 'GET'
      };
    
      const respuesta=await fetch(api_url+"session/"+session_id, requestOptions)
        .then((response)=>{
          return response.json();
        })
        .then((response)=>{
          return response;
        });

      return respuesta;
}

const Resultado = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const session_id = searchParams.get('session_id')
    const [cargando, setcargando] = useState(true);
    const [session, setsession] = useState(null);

    useEffect(() => {
            cargarSession(session_id).then(data => {
                setcargando(false);
                setsession(data);
                console.log(data);
            });
    },[])

    if (session_id==='' || session_id===null) {
        return cargando ?
            <>
              <div className='loading'>
              </div>
              <div className='row'>
                <div className='col-md-12 text-center mt-3'>
                  <h4>Generando entradas, espere un momento...</h4>
                </div>
              </div>
            </>
            
            :
            <ErrorPago/>
    }else{
        return session=== null ?
            <>
              <div className='loading'>
              </div>
              <div className='row'>
                <div className='col-md-12 text-center mt-3'>
                  <h4>Procesando pago. espera un momento, esta acción podría tardar varios segundos...</h4>
                </div>
              </div>
            </>
            :
            <ResultadoPago session={session}></ResultadoPago>
       
    }
    
}

export default Resultado