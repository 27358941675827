export const Global = {
    apiurl: 'https://entradasadmin.penaflor.cl/api/v1/', 
    base: 'https://entradasadmin.penaflor.cl/', 
    inicio:'https://entradas.penaflor.cl',
    url_descuentos:'https://descuentosadmin.penaflor.cl/publico/formulario?id=356a192b7913b04c54574d18c28d46e6395428ab'
}
/*
export const Global = {
    apiurl: 'https://eventos2.penaflor.cl/api/v1/', 
    base: 'https://eventos2.penaflor.cl/', 
    inicio:'https://entradas.penaflor.cl',
    url_descuentos:'https://descuentosadmin.penaflor.cl/publico/formulario?id=356a192b7913b04c54574d18c28d46e6395428ab'
}*/
