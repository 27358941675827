import React from 'react'
import Error from '../../assets/img/error.png';
import { Global } from '../../utils/Global';
const ErrorPago = ({session_id=null}) => {

  const url_principal=Global.inicio;

  return (
    <div className='row'>
        <div className='col-md-12 mt-5 text-center'>
            <img src={Error} width={100}></img>
            <h1>Error en la transacción</h1>
            <p>No pudimos realizar la transacción ya que ocurrió un error durante el proceso.<br></br>
            Si el dinero fue descontado de tu cuenta puedes comunicarte con nosotros a través del siguiente correo electrónico:
            <b className='text-primary'>eventos@penaflor.cl</b>
            <br></br>
            <br></br>
            {
                session_id!==null ? <h4>N° de tu Orden: <b className='text-warning'>{session_id}</b></h4>: <></>
            }
            </p>
            <br>
            </br>
            <a
            href={url_principal}
            class="btn btn-success">Volver al Inicio</a>
        </div>
    </div>
  )
}

export default ErrorPago